// Core libraries
import React, { useEffect, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectors } from '../store';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';

// Internal libs

// Internal components
import SwipeableTabPanels from '@common/components/tabs/SwipeableTabPanels';
import SwipeableTabPanel from '@common/components/tabs/SwipeableTabPanel';
import { ContractName, DriverContract } from '@features/admin/drivers/model/DriverContracts';
import DriverContractInfo from '@features/admin/drivers/conteiners/DriverContractInfo';
import { DocumentStatus, DocumentStatusEnum } from '@features/contracts/model/DocumentStatus';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

const findAvailableContract = (
  name: ContractName,
  availableContracts: DriverContract[] | undefined,
) => {
  return !availableContracts
    ? availableContracts
    : availableContracts.find((contract) => contract.name === name);
};

const disableSubmitButtonMap: Record<DocumentStatus, boolean> = {
  uploaded: false,
  accepted: false,
  rejected: true,
  terminated: true,
  pending: true,
};

// Props type
type DriverContractDetailsViewProps = {
  driverId: string;
  getFieldErrors: (
    fieldName: string,
    backendFieldName?: string,
    translateFullPrefix?: string,
  ) => string[];
  setContractName: (contractType: ContractName) => void;
  isSubmitInProgress: boolean;
  onSubmitButtonPress: (disabled: boolean) => void;
};

// Component
const DriverContractDetailsView = ({
  driverId,
  getFieldErrors,
  setContractName,
  isSubmitInProgress,
  onSubmitButtonPress,
}: DriverContractDetailsViewProps) => {
  const styles = useStyles();
  const { t } = useTranslation('contracts');
  const driver = useSelector(selectors.selectDriverById(driverId));
  const availableContracts: DriverContract[] | undefined = driver?.availableContracts;
  const [tab, setTab] = useState('property');
  const propertyContract = findAvailableContract(ContractName.property, availableContracts);
  const transportContract = findAvailableContract(ContractName.transport, availableContracts);
  // This sets default button state
  useEffect(() => {
    onSubmitButtonPress(
      tab === 'property'
        ? propertyContract?.contractStatus
          ? disableSubmitButtonMap[propertyContract.contractStatus as DocumentStatusEnum]
          : true
        : transportContract?.contractStatus
        ? disableSubmitButtonMap[transportContract.contractStatus as DocumentStatusEnum]
        : true,
    );
  }, [availableContracts, onSubmitButtonPress, tab]);

  return (
    <div className={styles.root}>
      <TabContext value={tab}>
        <TabList
          onChange={(event, newValue) => {
            setTab(newValue);
            setContractName(
              newValue === 'property' ? ContractName.property : ContractName.transport,
            );
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            value="property"
            label={t('contractNames.property_entrustment_agreement.name')}
            disabled={!propertyContract}
          />
          <Tab
            value="transport"
            label={t('contractNames.transport_services_agreement.name')}
            disabled={!transportContract}
          />
        </TabList>
        <SwipeableTabPanels value={tab} onChangeValue={setTab} values={['property', 'transport']}>
          <SwipeableTabPanel value="property">
            {propertyContract && (
              <DriverContractInfo
                identityDocuments={driver?.identityDocuments}
                contract={propertyContract}
                getFieldErrors={getFieldErrors}
                disableUploadButton={isSubmitInProgress}
              />
            )}
          </SwipeableTabPanel>
          <SwipeableTabPanel value="transport">
            {transportContract && (
              <DriverContractInfo
                identityDocuments={driver?.identityDocuments}
                contract={transportContract}
                getFieldErrors={getFieldErrors}
                disableUploadButton={isSubmitInProgress}
              />
            )}
          </SwipeableTabPanel>
        </SwipeableTabPanels>
      </TabContext>
    </div>
  );
};

export default DriverContractDetailsView;
