export interface AllowedIdentityDocumentSrc {
  id: number;
  name: string;
  value: string;
  options: {
    required?: boolean;
  };
}

export interface AllowedIdentityDocument {
  id: number;
  name: string;
  value: string;
  options?: {
    required?: boolean;
  };
}

export interface IdentityDocument {
  id: number;
  documentName: string;
  documentType: string;
  fileName: string;
  downloadLink: string;
}

export interface IdentityDocumentSrc {
  id: number;
  document_name: string;
  document_type: string;
  file_name: string;
  download_link: string;
}

export const identityDocumentFromSrc = (
  identityDocumentSrc: IdentityDocumentSrc,
): IdentityDocument => ({
  id: identityDocumentSrc.id,
  documentName: identityDocumentSrc.document_name,
  documentType: identityDocumentSrc.document_type,
  fileName: identityDocumentSrc.file_name,
  downloadLink: identityDocumentSrc.download_link,
});

export const identityDocumentToSrc = (identityDocument: IdentityDocument): IdentityDocumentSrc => ({
  id: identityDocument.id,
  document_name: identityDocument.documentName,
  document_type: identityDocument.documentType,
  file_name: identityDocument.fileName,
  download_link: identityDocument.downloadLink,
});

export const allowedIdentityDocumentFromSrc = (
  identityDocumentSrc: AllowedIdentityDocumentSrc,
): AllowedIdentityDocument => ({
  ...identityDocumentSrc,
  options: {
    ...(identityDocumentSrc.options?.required !== undefined && {
      required: identityDocumentSrc.options?.required,
    }),
  },
});
