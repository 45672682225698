// Core libraries
import React, { useMemo, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { groupBy, isNil } from 'lodash';

// Internal libs
import { FailureResponse } from '@common/model';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import useRequiredAnyValidator from '@common/validators/requiredAnyValidator';
import usePeselValidator from '@common/validators/peselValidator';
import useIdNumberValidator from '@common/validators/idNumberValidator';
import { User } from '../model/User';
import { Region } from '../model/Region';

// Internal components
import FormTextField from '@common/components/FormTextField';
import FormSelectField from '@common/components/FormSelectField';
import FormAddressGrid from '@common/components/FormAddressGrid';
import { Nationality } from '@features/user/model/Nationality';
import { AllowedIdentityDocument } from '@features/user/model/IdentityDocument';
import { Divider } from '@material-ui/core';
import UploadIdentityDocumentsSection from '@features/user/containers/UploadIdentityDocumentsSection';
import UploadFile from '@common/components/UploadFile';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(1),
    },
    showField: {},
    divider: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type UserDataEditFormFieldsProps = {
  userData: User;
  regions: Region[];
  nationalities: Nationality[];
  allowedIdentityDocuments: AllowedIdentityDocument[];
  errors?: FailureResponse;
};

// Component
const UserDataEditFormFields = ({
  userData,
  regions,
  nationalities,
  allowedIdentityDocuments,
  errors,
}: UserDataEditFormFieldsProps) => {
  const { t } = useTranslation(['user', 'common']);
  const styles = useStyles();
  const { getFieldErrors } = useResponseErrors(errors, 'userDataEdit', 'user');
  const form = useFormContext();

  const [documentSelectionDefaultValue] = useState(
    userData.idNumber
      ? 'idNumber'
      : userData.passportNumber
      ? 'passportNumber'
      : 'residenceCardNumber',
  );
  const documentSelected = form.watch('mainDocumentSelection', documentSelectionDefaultValue);

  const passportOrIdRequiredValidator = useRequiredAnyValidator(
    form.getValues,
    ['idNumber', 'passportNumber', 'residenceCardNumber'],
    t('user:userDataEdit.fields.passportNumber.errors.idOrPassportNumberRequired', ''),
  );

  const idNumberValidator = useIdNumberValidator(
    t('user:userDataEdit.fields.idNumber.errors.idNumber', ''),
    userData.country?.code,
  );

  const peselValidator = usePeselValidator(t('user:userDataEdit.fields.pesel.errors.pesel', ''));

  const defaultNationality =
    userData?.nationalityId !== undefined
      ? nationalities.find((nationality) => nationality.id === userData.nationalityId)
      : nationalities.find((nationality) => nationality.options?.default === true);

  const selectedNationalityId = form.watch('nationalityId', defaultNationality?.id);

  const selectedNationality = useMemo(() => {
    return nationalities.find((nationality) => nationality.id === selectedNationalityId);
  }, [selectedNationalityId]);

  const identityDocumentGroups = useMemo(() => {
    return groupBy(allowedIdentityDocuments, 'name');
  }, [selectedNationality]);

  const uploadedIdentityDocumentGroup = useMemo(() => {
    return allowedIdentityDocuments.find(
      (allowedIdentityDocument) =>
        userData.identityDocuments?.[0]?.documentName === allowedIdentityDocument.value,
    );
  }, [userData]);

  const selectedIdentityDocumentGroup = form.watch(
    'identityDocumentGroup',
    uploadedIdentityDocumentGroup?.name,
  );

  const documentUploaded = (message: string) =>
    !isNil(uploadedIdentityDocumentGroup) || message || false;

  return (
    <>
      <Grid container spacing={2}>
        {/* First Name */}
        <Grid item xs={12} sm={6}>
          <FormTextField
            className={styles.field}
            name="firstName"
            label={t('user:user.fields.firstName', '')}
            rules={{
              required: {
                value: true,
                message: t('user:userDataEdit.fields.firstName.errors.required', ''),
              },
              minLength: {
                value: 3,
                message: t('user:userDataEdit.fields.firstName.errors.minLength', ''),
              },
            }}
            fullWidth
            errors={getFieldErrors('first_name')}
            defaultValue={userData?.firstName || ''}
          />
        </Grid>

        {/* Last Name */}
        <Grid item xs={12} sm={6}>
          <FormTextField
            className={styles.field}
            name="lastName"
            label={t('user:user.fields.lastName', '')}
            rules={{
              required: {
                value: true,
                message: t('user:userDataEdit.fields.lastName.errors.required', ''),
              },
              minLength: {
                value: 3,
                message: t('user:userDataEdit.fields.lastName.errors.minLength', ''),
              },
            }}
            fullWidth
            errors={getFieldErrors('last_name')}
            defaultValue={userData?.lastName || ''}
          />
        </Grid>

        {/* Nationality */}
        <Grid item xs={12} sm={6}>
          <FormSelectField
            className={styles.field}
            name="nationalityId"
            label={t('user:user.fields.nationality', '')}
            options={nationalities.map((nationality) => ({
              label: t(`common:nationalities.${nationality.value}`),
              value: nationality.id,
            }))}
            rules={{
              required: {
                value: true,
                message: t('user:userDataEdit.fields.nationality.errors.required', ''),
              },
            }}
            fullWidth={true}
            errors={getFieldErrors('nationalityId', 'nationality_id')}
            defaultValue={userData.nationalityId || defaultNationality?.id || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {selectedNationality?.options?.requiresCustomInput && (
            <FormTextField
              className={styles.field}
              name="customNationality"
              label={t('user:user.fields.customNationality', '')}
              rules={{
                required: {
                  value: true,
                  message: t('user:userDataEdit.fields.customNationality.errors.required', ''),
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('custom_nationality')}
              defaultValue={userData?.customNationality || ''}
            />
          )}
        </Grid>
        {/* Pesel */}
        {selectedNationality?.options?.requiresPesel && (
          <Grid item xs={12} sm={12}>
            <FormTextField
              className={styles.field}
              name="pesel"
              label={t('user:user.fields.pesel', '')}
              rules={{
                required: {
                  value: true,
                  message: t('user:userDataEdit.fields.pesel.errors.required', ''),
                },
                validate: {
                  pesel: peselValidator,
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('pesel')}
              defaultValue={userData?.pesel || ''}
              disabled={!!userData?.pesel}
            />
          </Grid>
        )}

        {/* ID Number or Passport selection */}
        <Grid item xs={12} sm={6}>
          <FormSelectField
            className={styles.field}
            name="mainDocumentSelection"
            label={t('user:user.fields.mainDocumentSelection', '')}
            options={[
              { label: t('user:user.fields.idNumber'), value: 'idNumber' },
              { label: t('user:user.fields.passportNumber'), value: 'passportNumber' },
              { label: t('user:user.fields.residenceCardNumber'), value: 'residenceCardNumber' },
            ]}
            rules={{}}
            defaultValue={documentSelectionDefaultValue}
          />
        </Grid>

        {/* Id Number */}
        <Grid item xs={12} sm={6}>
          {(documentSelected === 'idNumber' || (!documentSelected && userData.idNumber)) && (
            <FormTextField
              className={styles.field}
              name="idNumber"
              label={t('user:user.fields.idNumber', '')}
              rules={{
                validate: {
                  idOrPassportNumberRequired: passportOrIdRequiredValidator,
                  idNumber: idNumberValidator,
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('id_number')}
              defaultValue={userData?.idNumber || ''}
              forceRequiredMark
            />
          )}

          {(documentSelected === 'passportNumber' ||
            (!documentSelected && userData.passportNumber)) && (
            <FormTextField
              className={styles.field}
              name="passportNumber"
              label={t('user:user.fields.passportNumber', '')}
              rules={{
                validate: {
                  idOrPassportNumberRequired: passportOrIdRequiredValidator,
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('passport_number')}
              defaultValue={userData?.passportNumber || ''}
              forceRequiredMark
            />
          )}
          {(documentSelected === 'residenceCardNumber' ||
            (!documentSelected && userData.residenceCardNumber)) && (
            <FormTextField
              className={styles.field}
              name="residenceCardNumber"
              label={t('user:user.fields.residenceCardNumber', '')}
              rules={{
                validate: {
                  idOrPassportNumberRequired: passportOrIdRequiredValidator,
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('residence_card_number')}
              defaultValue={userData?.residenceCardNumber || ''}
              forceRequiredMark
            />
          )}
        </Grid>
      </Grid>

      {/* Address */}
      <FormAddressGrid getFieldErrors={getFieldErrors} address={userData?.address} />

      {/* Region */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormSelectField
            className={styles.field}
            name="regionId"
            label={t('user:user.fields.region', '')}
            options={regions.map((region) => ({ label: region.name, value: region.id }))}
            rules={{
              required: {
                value: true,
                message: t('user:userDataEdit.fields.region.errors.required', ''),
              },
            }}
            fullWidth={true}
            errors={getFieldErrors('regionId', 'region_id')}
            defaultValue={userData.regionId || ''}
          />
        </Grid>
      </Grid>
      <Divider className={styles.divider} />
      {selectedNationality?.options?.requiresIdentityDocument && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <p>{t('user:userDataEdit.identityDocumentsRequirementInfo')}</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormSelectField
              className={styles.field}
              name="identityDocumentGroup"
              label={t('user:user.fields.identityDocumentGroup', '')}
              options={Object.keys(identityDocumentGroups).map((group) => ({
                label: t(`common:identityDocument.groups.${group}`),
                value: group,
              }))}
              rules={{
                required: {
                  value: true,
                  message: t('user:userDataEdit.fields.identityDocumentGroup.errors.required', ''),
                },
                validate: {
                  documentUploaded: () =>
                    documentUploaded(
                      t(
                        'user:userDataEdit.fields.identityDocumentGroup.errors.documentUploaded',
                        '',
                      ),
                    ),
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('identityDocumentGroup', 'identity_document_group')}
              defaultValue={uploadedIdentityDocumentGroup?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadIdentityDocumentsSection
              allowedIdentityDocuments={identityDocumentGroups[selectedIdentityDocumentGroup]}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserDataEditFormFields;
