// Core libraries
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch, useLocation } from 'react-router';
import {
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
// @ts-ignore
import { find, flatMap, uniqBy, map, some } from 'lodash';

// Internal libs
import { actions, selectors } from '../store';
import { DriverContract } from '@features/admin/drivers/model/DriverContracts';
import { Driver } from '@common/model/Driver';
import { AccountTypeEnum } from '@features/user/model/Settlements';

// Internal components
import TableFiltersToolbar from '@common/components/filters/TableFiltersToolbar';
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import TableFiltersProvider from '@common/components/filters/TableFiltersProvider';
import DriversFilter, { DriversFilterData } from '@features/admin/drivers/conteiners/DriversFilter';
import LinearProgress from '@material-ui/core/LinearProgress';
import DriverContractsStatus from '@features/admin/drivers/conteiners/DriverContractsStatus';
import DriversToolbarSelect from '@features/admin/drivers/conteiners/DriversToolbarSelect';
import { XpressDeliveryCompany } from '@common/model/XpressDeliveryCompany';
import CommentListItem from '@features/admin/components/CommentsContainer/CommentListItem';
import { Comment } from '@common/model/Comment';
import { PaginatedData } from '@common/model';
import Button from '@material-ui/core/Button';
import { Region } from '@features/user/model/Region';
import { DateTimeSelect } from '@common/components/form/DateTimeSelect';
import moment from 'moment';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

const changeDateFormat = (date: string | undefined): string => {
  if (!date) return '';
  return new Date(date).toLocaleString().slice(0, -3);
};

type CustomFilterSelectProps = {
  label: string;
  optionValues: { name: string; display: string }[];
  renderValue: Function;
  index: any;
  filterList: string[][];
  onChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
};

const CustomFilterSelect = ({
  label,
  optionValues,
  renderValue,
  filterList,
  index,
  onChange,
}: CustomFilterSelectProps) => {
  return (
    <FormControl>
      <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
      <Select
        multiple
        value={filterList[index]}
        renderValue={(selected) => renderValue(selected)}
        onChange={onChange}
      >
        {optionValues.map((item: { name: string; display: string }) => (
          <MenuItem key={item.name} value={item.name}>
            <Checkbox color="primary" checked={filterList[index].includes(item.name)} />
            <ListItemText primary={item.display} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Props type
type DriversTableProps = {
  drivers?: PaginatedData<Driver>;
  companies: XpressDeliveryCompany[];
  setFilters: Function;
  regions: Region[];
};

// Component
const DriversTablePaginated = ({ drivers, companies, setFilters, regions }: DriversTableProps) => {
  const { t } = useTranslation(['admin', 'vehicles', 'common']);
  const { path } = useRouteMatch();
  const getDriversFilters = useSelector(selectors.selectGetDriversFilters);
  const getReadableAccountType = (
    accountType: {
      name: AccountTypeEnum | undefined;
      companyName?: string | undefined;
      isPartner?: boolean | undefined;
    },
    emptyValue: string,
  ): string => {
    if (!accountType.name) {
      return emptyValue;
    }
    return accountType.name === AccountTypeEnum.company
      ? accountType.isPartner
        ? `${t(`admin:drivers.accountType.partner`)}`
        : t(`admin:drivers.accountType.company`)
      : t(`admin:drivers.accountType.${accountType.name}`, accountType.name);
  };
  const filteredDrivers = useMemo(() => {
    return (
      drivers?.data
        //  Search text
        ?.map((driver: Driver) => ({
          ...driver,
          driver,
          accountType: {
            name: driver.accountType,
            isPartner: driver.isPartner,
          },
          contracts: {
            driverId: driver.id,
            contracts: (driver.availableContracts || []).map((contract) => ({
              ...contract,
              contractStatusTranslated: t(
                `admin:drivers.agreementStatus.${contract.contractStatus || null}`,
              ),
            })),
          },
        })) || []
    );
  }, [drivers]);

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'id',
        label: t('admin:drivers.fields.id'),
        options: {
          filter: false,
        },
      },
      {
        name: 'countryCode',
        label: t('admin:drivers.fields.country'),
        options: {
          filter: false,
          customBodyRender: (value: string) =>
            value ? t(`common:countries.${value}`, value) : '-',
        },
      },
      {
        name: 'region',
        label: t('admin:drivers.fields.region'),
        options: {
          customFilterListOptions: {
            render: (values) =>
              values.map((value: string) => {
                const region = find(regions, { id: +value });
                // @ts-ignore
                return region?.name || value;
              }),
          },
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = regions.map((region) => ({
                name: region.id.toString(),
                display: region.name,
              }));
              return (
                <CustomFilterSelect
                  label={t('admin:drivers.fields.region')}
                  optionValues={optionValues}
                  // @ts-ignore
                  renderValue={(selected) =>
                    selected
                      ?.map((value: string) => {
                        const region = find(regions, { id: +value });
                        // @ts-ignore
                        return region?.name || value;
                      })
                      .join(', ')
                  }
                  index={index}
                  filterList={filterList}
                  onChange={(event) => {
                    // @ts-ignore
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              );
            },
          },
        },
      },
      {
        name: 'allowedCompanies',
        label: t('admin:drivers.fields.allowedCompanies'),
        options: {
          customBodyRender: (value: XpressDeliveryCompany[]) => {
            return (value || []).map((company: XpressDeliveryCompany) => company.name).join(', ');
          },
          customFilterListOptions: {
            render: (values) =>
              values.map((value: string) => {
                const company = find(companies, { id: +value });
                // @ts-ignore
                return company?.name || value;
              }),
          },
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = companies.map((company) => ({
                name: company.id.toString(),
                display: company.name,
              }));
              return (
                <CustomFilterSelect
                  label={t('admin:drivers.fields.allowedCompanies')}
                  optionValues={optionValues}
                  // @ts-ignore
                  renderValue={(selected) =>
                    selected
                      ?.map((value: string) => {
                        const company = find(companies, { id: +value });
                        // @ts-ignore
                        return company?.name || value;
                      })
                      .join(', ')
                  }
                  index={index}
                  filterList={filterList}
                  onChange={(event) => {
                    // @ts-ignore
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              );
            },
          },
        },
      },
      {
        name: 'firstName',
        label: t('admin:drivers.fields.firstName'),
        options: {
          filterType: 'textField',
          customBodyRender: (value: string) => value || '-',
          customFilterListOptions: {
            render: (value) => `${t('admin:drivers.fields.firstName')}: ${value}`,
          },
        },
      },
      {
        name: 'lastName',
        label: t('admin:drivers.fields.lastName'),
        options: {
          filterType: 'textField',
          customBodyRender: (value: string) => value || '-',
          customFilterListOptions: {
            render: (value) => `${t('admin:drivers.fields.lastName')}: ${value}`,
          },
        },
      },
      {
        name: 'email',
        label: t('admin:drivers.fields.email'),
        options: {
          filterType: 'textField',
          customFilterListOptions: {
            render: (value) => `${t('admin:drivers.fields.email')}: ${value}`,
          },
        },
      },
      {
        name: 'emailVerifiedAt',
        label: t('admin:drivers.fields.emailVerified'),
        options: {
          filter: false,
          customBodyRender: (value: string) =>
            value
              ? t('admin:drivers.dataVerified.verified')
              : t('admin:drivers.dataVerified.notVerified'),
          customFilterListOptions: {
            render: (v: string) => `${t('admin:drivers.fields.emailVerified')}: ${v}`,
          },
        },
      },
      {
        name: 'phone',
        label: t('admin:drivers.fields.phone'),
        options: {
          filterType: 'textField',
          customFilterListOptions: {
            render: (value) => `${t('admin:drivers.fields.phone')}: ${value}`,
          },
          customBodyRender: (value: string) => value || '-',
        },
      },
      {
        name: 'phoneVerifiedAt',
        label: t('admin:drivers.fields.phoneVerified'),
        options: {
          filter: false,
          customBodyRender: (value: string) =>
            value
              ? t('admin:drivers.dataVerified.verified')
              : t('admin:drivers.dataVerified.notVerified'),
          customFilterListOptions: {
            render: (v: string) => `${t('admin:drivers.fields.phoneVerified')}: ${v}`,
          },
        },
      },
      {
        name: 'isPersonalDataCompleted',
        label: t('admin:drivers.fields.personalDataCompleted'),
        options: {
          filter: false,
          customBodyRender: (value: string) =>
            value
              ? t('admin:drivers.personalData.verified')
              : t('admin:drivers.personalData.notVerified'),
          customFilterListOptions: {
            render: (v: string) => `${t('admin:drivers.fields.personalDataCompleted')}: ${v}`,
          },
        },
      },
      {
        name: 'accountType',
        label: t('admin:drivers.fields.accountType'),
        options: {
          filter: false,
          customBodyRender: (value: {
            name: AccountTypeEnum;
            companyName?: string;
            isPartner?: boolean;
          }) => getReadableAccountType(value, '-'),
          filterOptions: {
            names: [
              '',
              t('admin:drivers.accountType.person'),
              t('admin:drivers.accountType.company'),
              t('admin:drivers.accountType.partner'),
            ],
          },
        },
      },
      {
        name: 'companyName',
        label: t('admin:drivers.fields.settledBy'),
        options: {
          filterType: 'textField',
          customFilterListOptions: {
            render: (value) => `${t('admin:drivers.fields.settledBy')}: ${value}`,
          },
        },
      },
      {
        name: 'vehicle',
        label: t('admin:drivers.fields.vehicle'),
        options: {
          filterType: 'custom',
          customFilterListOptions: {
            render: (values) =>
              values.map((value: string) =>
                value ? t(`vehicles:fields.vehicleType.types.${value}`) : '',
              ),
          },

          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = [
                { name: 'none', display: '-' },
                { name: 'car', display: t('vehicles:fields.vehicleType.types.car') },
                { name: 'motorbike', display: t('vehicles:fields.vehicleType.types.motorbike') },
                { name: 'bicycle', display: t('vehicles:fields.vehicleType.types.bicycle') },
                { name: 'pedestrian', display: t('vehicles:fields.vehicleType.types.pedestrian') },
                { name: 'scooter', display: t('vehicles:fields.vehicleType.types.scooter') },
              ];
              return (
                <CustomFilterSelect
                  label={t('admin:drivers.fields.vehicle')}
                  optionValues={optionValues}
                  // @ts-ignore
                  renderValue={(selected) =>
                    selected
                      ?.map((value: string) => t(`vehicles:fields.vehicleType.types.${value}`))
                      .join(', ')
                  }
                  index={index}
                  filterList={filterList}
                  onChange={(event) => {
                    // @ts-ignore
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              );
            },
          },
          customBodyRender: (value: string) =>
            value ? t(`vehicles:fields.vehicleType.types.${value}`, value) : '-',
        },
      },
      {
        name: 'createdAt',
        label: t('admin:drivers.fields.createdAt'),
        options: {
          customFilterListOptions: {
            render: (values) => {
              return `${t('admin:drivers.fields.createdAt')}: ${
                values[0]
                  ? `${t('admin:drivers.filters.createdAt.from')} ${changeDateFormat(values[0])}`
                  : ''
              } ${
                values[1]
                  ? `${t('admin:drivers.filters.createdAt.to')} ${changeDateFormat(values[1])}`
                  : ''
              }`;
            },
          },
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              return (
                <>
                  <DateTimeSelect
                    label={t('admin:drivers.filters.createdAt.dateFrom') as string}
                    onChange={(date) => {
                      // @ts-ignore
                      filterList[index][0] = date?.toDate();

                      onChange(filterList[index], index, column);
                    }}
                    value={filterList[index][0] ? moment(filterList[index][0]) : null}
                    name={'dateFrom'}
                  />
                  <DateTimeSelect
                    label={t('admin:drivers.filters.createdAt.dateTo') as string}
                    onChange={(date) => {
                      // @ts-ignore
                      filterList[index][1] = date?.toDate();

                      onChange(filterList[index], index, column);
                    }}
                    value={filterList[index][1] ? moment(filterList[index][1]) : null}
                    name={'dateTo'}
                  />
                </>
              );
            },
          },
          display: false,
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : '-'),
        },
      },
      {
        name: 'partnerRequestedAt',
        label: t('admin:drivers.fields.partnerRequestedAt'),
        options: {
          display: false,
          filter: false,
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : '-'),
        },
      },
      {
        name: 'lastComment',
        label: t('admin:drivers.fields.lastComment'),
        options: {
          filter: false,
          customBodyRender: (value: Comment) =>
            value ? <CommentListItem displayDate={false} item={value} /> : '-',
        },
      },
      {
        name: 'contracts',
        label: t('admin:drivers.fields.contracts'),
        options: {
          filterType: 'custom',
          customFilterListOptions: {
            render: (values) =>
              values.map((value: string) =>
                value
                  ? `${t('admin:drivers.fields.contracts')}: ${t(
                      `admin:drivers.agreementStatus.${value}`,
                    )}`
                  : '',
              ),
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = [
                { name: 'null', display: t('admin:drivers.agreementStatus.null') },
                { name: 'uploaded', display: t('admin:drivers.agreementStatus.uploaded') },
                { name: 'pending', display: t('admin:drivers.agreementStatus.pending') },
                { name: 'accepted', display: t('admin:drivers.agreementStatus.accepted') },
                { name: 'rejected', display: t('admin:drivers.agreementStatus.rejected') },
                { name: 'terminated', display: t('admin:drivers.agreementStatus.terminated') },
              ];
              return (
                <CustomFilterSelect
                  label={t('admin:drivers.fields.contracts')}
                  optionValues={optionValues}
                  // @ts-ignore
                  renderValue={(selected) =>
                    selected
                      ?.map((value: string) => t(`admin:drivers.agreementStatus.${value}`))
                      .join(', ')
                  }
                  index={index}
                  filterList={filterList}
                  onChange={(event) => {
                    // @ts-ignore
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              );
            },
          },
          customBodyRender: (value: { driverId: number; contracts: DriverContract[] }) => (
            <DriverContractsStatus contracts={value.contracts} driverId={value.driverId} />
          ),
        },
      },
    ],
    [],
  );

  const handleFilterSubmit = (applyFilters: any) => {
    let filterList = applyFilters();
    const filterColumn: {
      [filterName: string]: any;
    } = {};

    for (let i in filterList) {
      // @ts-ignore
      const column = columns[i];
      if (filterList[i].length > 0) {
        if (column?.options?.filterType === 'textField') {
          filterColumn[column.name as string] = filterList[i].toString();
        } else if (column.name === 'createdAt') {
          if (filterList[i][0]) {
            filterColumn['createdAtFrom'] = filterList[i][0];
          }
          if (filterList[i][1]) {
            filterColumn['createdAtTo'] = filterList[i][1];
          }
        } else {
          filterColumn[column.name as string] = filterList[i];
        }
      }
    }
    setFilters(filterColumn);
  };

  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(false),
    sort: false,
    search: false,
    download: true,
    count: drivers?.total,
    serverSide: true,
    rowsPerPageOptions: [50, 100, 200],
    // @ts-ignore
    rowsPerPage: drivers?.perPage && parseInt(drivers.perPage),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setFilters({
            ...getDriversFilters,
            page: tableState.page + 1,
            itemsPerPage: tableState.rowsPerPage,
          });
          break;

        case 'changeRowsPerPage':
          setFilters({
            ...getDriversFilters,
            page: 1,
            itemsPerPage: tableState.rowsPerPage,
          });
          break;

        default:
      }
    },
    onTableInit: (action, tableState) => {
      // Remove other filters
      setFilters({
        page: tableState.page,
        itemsPerPage: tableState.rowsPerPage,
      });
    },
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>
            {t('admin:drivers.filters.submit')}
          </Button>
        </div>
      );
    },
    onFilterChange: (column, filterList, type) => {
      // FIXME: for some reason only createdAt column gets picked up as "custom" when removing chip from filters
      if (type === 'chip' || column === 'createdAt') {
        const newFilters = () => filterList;
        handleFilterSubmit(newFilters);
      }
    },
    selectableRows: 'multiple',
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      },
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <DriversToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        driversInTable={filteredDrivers}
      />
    ),
    onDownload: (buildHead, buildBody, columns, data) => {
      const customRenderColumns = [
        'transport_services_agreement',
        'transport_services_agreement_date',
        'property_entrustment_agreement',
        'property_entrustment_agreement_date',
      ];
      columns.pop();
      columns.push({
        name: 'transport_services_agreement',
        label: t('admin:drivers.servicesAgreement.transport_services_agreement'),
        download: true,
        customBodyRender: (value: any) => t(`admin:drivers.agreementStatus.${value || null}`),
      });
      columns.push({
        name: 'transport_services_agreement_date',
        label: t('admin:drivers.servicesAgreement.transport_services_agreement_date'),
        download: true,
        customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : '-'),
      });
      columns.push({
        name: 'property_entrustment_agreement',
        label: t('admin:drivers.servicesAgreement.property_entrustment_agreement'),
        download: true,
        customBodyRender: (value: any) => t(`admin:drivers.agreementStatus.${value || null}`),
      });
      columns.push({
        name: 'property_entrustment_agreement_date',
        label: t('admin:drivers.servicesAgreement.property_entrustment_agreement_date'),
        download: true,
        customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : '-'),
      });
      data.map((driver: any) => {
        const contracts = driver.data.pop();
        driver.data.push(
          find(contracts.contracts, { name: 'transport_services_agreement' })?.contractStatus ||
            null,
        );
        driver.data.push(
          find(contracts.contracts, { name: 'transport_services_agreement' })?.contractAcceptedAt ||
            null,
        );
        driver.data.push(
          find(contracts.contracts, { name: 'property_entrustment_agreement' })?.contractStatus ||
            null,
        );
        driver.data.push(
          find(contracts.contracts, { name: 'property_entrustment_agreement' })
            ?.contractAcceptedAt || null,
        );
        driver.data = driver.data.map((value: any, index: number) => {
          const renderer = columns[index];
          if (customRenderColumns.includes(renderer.name)) {
            return renderer.customBodyRender(value);
          }
          return value;
        });
        return driver;
      });
      return `${buildHead(columns)}${buildBody(data)}`.trim();
    },
    onRowClick: (currentRowsSelected: any, allRowsSelected: any) => {
      const selection = window.getSelection() || '';
      return selection.toString().length === 0
        ? window
            ?.open(
              `${window?.location?.origin}${path}/driver/${currentRowsSelected[0]}/profile`,
              '_blank',
            )
            ?.focus()
        : null;
    },
  };

  return (
    <MUIDataTable
      columns={columns}
      data={filteredDrivers}
      title=""
      options={options}
      components={{
        TableToolbar: TableFiltersToolbar,
      }}
    />
  );
};

export default DriversTablePaginated;
