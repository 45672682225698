import { RootState } from '@app/store';
import { User, UserDataFlags } from '@features/user/model/User';
import { AccountTypeEnum } from '@features/user/model/Settlements';
import { InProgressState } from '@common/model';

export const selectGetUserState = (state: RootState): InProgressState | undefined =>
  state.user.getUserState;
export const selectUser = (state: RootState): User | undefined => state.user.user;

export const selectSetLanguageState = (state: RootState): InProgressState | undefined =>
  state.user.setLanguageState;

export const selectSetContractLanguageState = (state: RootState): InProgressState | undefined =>
  state.user.setContractLanguageState;

export const selectUserLoadedAndHasCompany = (state: RootState): boolean =>
  !!state.user.user?.company;

export const selectUserLoadedAndIsPartner = (state: RootState): boolean =>
  !!state.user.user?.company?.isPartner;

export const selectUserDataFlags = (state: RootState): UserDataFlags | undefined =>
  state.user.user && {
    missingPersonalData: !state.user.user.firstName || !state.user.user.lastName,
    missingVehicleData: !state.user.user.activeVehicle,
    missingEmailVerification: !state.user.user.emailVerifiedAt,
    missingPhone: !state.user.user.phone,
    missingPhoneVerification: !state.user.user.phoneVerifiedAt,
    missingContracts: state.user.user?.missingContractsCount !== 0,
  };

export const selectUserMissingPersonalData = (state: RootState): boolean | undefined =>
  state.user.user && (!state.user.user.firstName || !state.user.user.lastName);

export const selectUserMissingVehicleData = (state: RootState): boolean | undefined =>
  state.user.user && !state.user.user.activeVehicle;

export const selectUserMissingEmailVerification = (state: RootState): boolean | undefined =>
  state.user.user && !state.user.user.emailVerifiedAt;

export const selectUserEmailVerified = (state: RootState): boolean | undefined =>
  state.user.user && !!state.user.user.emailVerifiedAt;

export const selectUserMissingPhone = (state: RootState): boolean | undefined =>
  state.user.user && !state.user.user.phone;

export const selectUserMissingPhoneVerification = (state: RootState): boolean | undefined =>
  state.user.user && !state.user.user.phoneVerifiedAt;

export const selectUserPhoneVerified = (state: RootState): boolean | undefined =>
  state.user.user && !!state.user.user.phoneVerifiedAt;

export const selectUserMissingPrivacyPolicyConsent = (state: RootState): boolean | undefined =>
  state.user.user && !state.user.user.privacyPolicyAcceptedAt;

// export const selectUserMissingGdprConsent = (state: RootState): boolean | undefined =>
//   state.user.user && !state.user.user.gdprAcceptedAt;

export const selectUserMissingAccountType = (state: RootState): boolean | undefined =>
  state.user.user && !state.user.user.accountType;

export const selectUserMissingPartner = (state: RootState): boolean | undefined =>
  state.user.user &&
  state.user.user.accountType === AccountTypeEnum.person &&
  !state.user.user.partnerId;

export const selectUserMissingPartnerOrRequest = (state: RootState): boolean | undefined =>
  state.user.user &&
  state.user.user.accountType === AccountTypeEnum.person &&
  !state.user.user.partnerId &&
  !state.user.user.partnerRequest;

export const selectUserMissingCompany = (state: RootState): boolean | undefined =>
  state.user.user &&
  state.user.user.accountType === AccountTypeEnum.company &&
  !state.user.user.company;

export const selectPartnerDriversCount = (state: RootState): number | undefined =>
  state.user.user?.driversCount;

export const selectPartnerPendingDriverJoinRequestsCount = (state: RootState): number | undefined =>
  state.user.user?.driversPendingJoinRequestsCount;

export const selectMissingContractsCount = (state: RootState): number | undefined =>
  state.user.user?.missingContractsCount;

export const selectPendingContractsCount = (state: RootState): number | undefined =>
  state.user.user?.pendingContractsCount;
