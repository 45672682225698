// Core libraries
import React, { useCallback, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import SuccessIcon from '@material-ui/icons/Check';

// Internal libs
import { InProgressState } from '@common/model/InProgressState';

// Internal components
import FormErrors from '@common/components/FormErrors';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    progress: { marginBottom: theme.spacing(1) },
    mainElements: { display: 'flex', alignItems: 'center' },
    chooseButton: {
      flexShrink: 0,
    },
    input: {
      display: 'none',
    },
    fileName: {
      flexShrink: 1,
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      wordWrap: 'break-word',
      overflow: 'hidden',
    },
    successIcon: {
      color: theme.palette.success.main,
    },
  }),
);

// Props type
type UploadFileProps = {
  onUpload: (
    file: File,
    onUploadProgress: (event: { loaded: number; total: number }) => void,
  ) => void;
};

// Component
const UploadFile = ({ onUpload }: UploadFileProps) => {
  const { t } = useTranslation('common');
  const styles = useStyles();

  const [file, setFile] = useState<any>();

  const [uId] = useState(() => 'btn-upload-' + Math.floor(Math.random() * 1000000));

  const selectFile = (event: any) => {
    setFile(event.target?.files ? event.target.files[0] : undefined);
    setUploadState({ inProgress: false });
  };

  const [uploadState, setUploadState] = useState<InProgressState>({ inProgress: false });
  const [errors, setErrors] = useState<string[] | undefined>();

  const upload = async () => {
    setUploadState({ inProgress: true });
    try {
      await onUpload(file, uploadProgress);
      setUploadState({ inProgress: false, success: true });
    } catch (e) {
      setUploadState({ inProgress: false, success: false });
      setErrors(e);
    }
  };

  const [progress, setProgress] = useState<number | undefined>();
  const uploadProgress = useCallback((event: { loaded: number; total: number }) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  }, []);

  return (
    <div className={styles.root}>
      {uploadState.inProgress && (
        <LinearProgress
          data-testid="upload-progress"
          variant="determinate"
          value={progress || 0}
          className={styles.progress}
        />
      )}
      <div className={styles.mainElements}>
        {!uploadState.success && (
          <label htmlFor={uId} className={styles.chooseButton}>
            <input
              data-testid="btn-upload"
              id={uId}
              type="file"
              onChange={selectFile}
              className={styles.input}
            />
            <Button
              variant="outlined"
              component="span"
              size="small"
              disabled={uploadState.inProgress}
            >
              {t('chooseFile')}
            </Button>
          </label>
        )}
        <div className={styles.fileName}>{file && file.name}</div>
        {!uploadState.success && (
          <Button
            variant="contained"
            size="small"
            disabled={!file || uploadState.inProgress}
            onClick={upload}
          >
            {t('upload')}
          </Button>
        )}
        {uploadState.success && <SuccessIcon className={styles.successIcon} />}
      </div>
      {errors?.length && <FormErrors errorMessages={errors} />}
    </div>
  );
};

export default UploadFile;
