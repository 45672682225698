import { RootState } from '@app/store';
import { Driver } from '@common/model/Driver';
import { ContractName, DriverContract } from '@features/admin/drivers/model/DriverContracts';

export const selectGetDriversState = (state: RootState) => state.admin.drivers.getDriversState;

export const selectGetDriversFilters = (state: RootState) => state.admin.drivers.getDriversFilters;

export const selectDriversPaginated = (state: RootState) =>
  state.admin.drivers.driversListPaginated;

export const selectDrivers = (state: RootState) => state.admin.drivers.driversList;

export const selectDriverById = (driverId?: string) => (state: RootState) =>
  state.admin.drivers.driversListPaginated?.data?.find(
    (driver: Driver) => driver.id.toString() === driverId,
  );

export const selectDriverAgreement = (driverId: string, name: ContractName) => (
  state: RootState,
) => {
  const driver = state.admin.drivers.driversListPaginated?.data?.find(
    (driver: Driver) => driver.id.toString() === driverId,
  );

  return driver && driver.availableContracts
    ? driver.availableContracts.find((contract: DriverContract) => contract.name === name)
    : undefined;
};
