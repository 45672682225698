import {
  CorrectionUpdate,
  DriverRideCorrection,
  driverRideCreateCorrectionToSrc,
  driverRideUpdateCorrectionToSrc,
} from '@features/admin/drivers/model/DriverRideCorrection';
import axios from 'axios';
import { PaginatedData, paginatedDataFromSrc } from '@common/model';
import { Ride, rideFromSrc, RideSrc } from '@features/rides/model';
import { Driver, driverFromSrc, DriverSrc } from '@common/model/Driver';
import {
  XpressDeliveryCompany,
  xpressDeliveryCompanyFromSrc,
} from '@common/model/XpressDeliveryCompany';
import { commentFromSrc, CommentSeverityEnum, CommentSrc } from '@common/model/Comment';
import { GetDriversFilters, GetDriversFiltersSrc } from '@features/admin/drivers/store/model';

export const addDriverRideCorrection = async (createdCorrection: DriverRideCorrection) => {
  return axios.post('/admin/ride/create', driverRideCreateCorrectionToSrc(createdCorrection));
};

export const updateDriverRideCorrection = async (
  rideId: number,
  createdCorrection: CorrectionUpdate,
) => {
  return axios.patch(`/admin/ride/${rideId}`, driverRideUpdateCorrectionToSrc(createdCorrection));
};

export const removeDriverRideCorrection = async (rideId: number) => {
  return axios.delete(`/admin/ride/${rideId}`);
};

export const getDriverRides = async (
  partnerId?: number,
  page?: number,
  showTotalItems?: number,
  rideNo?: string,
): Promise<PaginatedData<Ride>> => {
  const response = await axios.get('/admin/ride/list', {
    params: {
      show_total_items: showTotalItems,
      page,
      ...(partnerId ? { user_id: partnerId } : {}),
      ...(rideNo ? { ride_no: rideNo } : {}),
    },
  });
  return paginatedDataFromSrc<RideSrc, Ride>(response.data, rideFromSrc);
};

export const getDriversList = async (showDeleted: boolean): Promise<Driver[]> => {
  const response = await axios.get('/admin/user/list', {
    params: {
      ...(showDeleted ? { deleted: 1 } : {}),
    },
  });
  return response?.data.map(driverFromSrc) || [];
};

export const getDriversListPaginated = async (
  filters: GetDriversFilters,
): Promise<PaginatedData<Driver>> => {
  const response = await axios.get('/admin/user/list-paginated', {
    params: getDriversListPaginatedFiltersToSrc(filters),
  });
  return paginatedDataFromSrc<DriverSrc, Driver>(response.data, driverFromSrc);
};

const getDriversListPaginatedFiltersToSrc = (filters: GetDriversFilters): GetDriversFiltersSrc => {
  return {
    page: filters.page ?? 1,
    items_per_page: filters.itemsPerPage ?? 50,
    deleted: filters.showDeleted ? 1 : 0,
    ...(filters.region && { region: filters.region }),
    ...(filters.lastName && { last_name: filters.lastName }),
    ...(filters.firstName && { first_name: filters.firstName }),
    ...(filters.contracts && { contract_status: filters.contracts }),
    ...(filters.vehicle && { vehicle: filters.vehicle }),
    ...(filters.email && { email: filters.email }),
    ...(filters.allowedCompanies && { allowed_companies_ids: filters.allowedCompanies }),
    ...(filters.createdAtFrom && { created_at_from: filters.createdAtFrom.toISOString() }),
    ...(filters.createdAtTo && { created_at_to: filters.createdAtTo.toISOString() }),
    ...(filters.companyName && { partner_name: filters.companyName }),
    ...(filters.phone && { phone: filters.phone }),
  };
};

export const getDriverProfile = async (driverId: number): Promise<Driver> => {
  const response = await axios.get(`/admin/user/${driverId}`);
  return driverFromSrc(response?.data) || undefined;
};

export const getXpressDeliveryCompanies = async (): Promise<XpressDeliveryCompany[]> => {
  const response = await axios.get('/admin/misc/xpress_delivery_companies');
  return response?.data.map(xpressDeliveryCompanyFromSrc) || [];
};

export const setDriverAllowedCompanies = async (
  driverId: number,
  allowedCompanies: string[],
): Promise<void> => {
  const response = await axios.post(`/admin/user/${driverId}/sync-allowed-companies`, {
    allowed_companies: allowedCompanies,
  });
};

export const setAllowedCompanyForDrivers = async (
  driverIds: string[],
  allowedCompanyId: number,
): Promise<void> => {
  const response = await axios.post('/admin/user/set-allowed-company', {
    driver_ids: driverIds,
    company_id: allowedCompanyId,
  });
};

export const removeAllowedCompanyForDrivers = async (
  driverIds: string[],
  allowedCompanyId: number,
): Promise<void> => {
  const response = await axios.post('/admin/user/remove-allowed-company', {
    driver_ids: driverIds,
    company_id: allowedCompanyId,
  });
};

export const deleteDrivers = async (driverIds: string[]): Promise<void> => {
  const response = await axios.post('/admin/user/delete', {
    driver_ids: driverIds,
    _method: 'DELETE',
  });
};

export interface CreateCommentPayload {
  body: string;
  severity: CommentSeverityEnum;
}

export const createDriverComment = async (
  driverId: string,
  payload: CreateCommentPayload,
): Promise<void> => {
  await axios.post(`/admin/user/${driverId}/comment`, {
    body: payload.body,
    severity: payload.severity,
  });
};

export const getDriverComments = async (driverId: string): Promise<Comment[]> => {
  const response = await axios.get(`/admin/user/${driverId}/comments`);
  return response?.data.map((comment: CommentSrc) => commentFromSrc(comment));
};

export const restoreDriver = async (driverId: string): Promise<void> => {
  await axios.post(`/admin/user/${driverId}/restore`);
};
