// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

// Internal libs
import { InProgressState } from '@common/model/InProgressState';

// Internal components
import { AllowedIdentityDocument, IdentityDocument } from '@features/user/model/IdentityDocument';
import UploadFile from '@common/components/UploadFile';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/Publish';
import DataColorActionIcon from '@common/components/dataViews/DataColorActionIcon';
import { actions as userActions, actions, selectors as userSelectors } from '@features/user';
import { useDispatch, useSelector } from 'react-redux';
import { mergeResponseErrors } from '@common/tools/mergeResponseErrors';
import { useShowSnackbar } from '@features/snackbars';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    progress: { marginBottom: theme.spacing(1) },
    input: {
      display: 'none',
    },
    fileName: {
      flexShrink: 1,
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      wordWrap: 'break-word',
      overflow: 'hidden',
    },
    successIcon: {
      color: theme.palette.success.main,
    },
    pendingIcon: {},
    itemActionContainer: {
      display: 'flex',
    },
  }),
);

// Props type
type UploadIdentityDocumentsSectionProps = {
  allowedIdentityDocuments: AllowedIdentityDocument[];
  inProgress?: boolean;
};

// Component
const UploadIdentityDocumentsSection = ({
  allowedIdentityDocuments,
}: UploadIdentityDocumentsSectionProps) => {
  const { t } = useTranslation(['common', 'user']);
  const styles = useStyles();
  const dispatch = useDispatch();
  const showSnackbar = useShowSnackbar();

  const identityDocuments = useSelector(userSelectors.selectIdentityDocuments);

  // Upload
  const [openUpload, setOpenUpload] = useState<string | null>(null);
  const toggleUpload = (documentName?: string) => {
    setOpenUpload(documentName && openUpload !== documentName ? documentName : null);
  };

  const uploadIdentityDocument = async (
    file: File,
    name: string,
    onUploadProgress: (event: { loaded: number; total: number }) => void,
  ) => {
    try {
      await dispatch(actions.uploadIdentityDocument(file, name, onUploadProgress));
      showSnackbar({
        severity: 'success',
        message: t('user:identityDocumentUpload.success'),
      });
      dispatch(actions.getDriverIdentityDocuments());
    } catch (e) {
      const errorMessages = mergeResponseErrors(e)
        .map((error: string) => t(`user:identityDocumentUpload.errors.${error}`, ''))
        .filter((msg: string) => !!msg);

      throw errorMessages.length
        ? errorMessages
        : [t('user:identityDocumentUpload.errors.unknown')];
    }
  };

  // Download uploaded document
  const [downloadDocumentState, setDownloadDocumentState] = useState<InProgressState>({
    inProgress: false,
  });
  const downloadIdentityDocument = async (documentLink: string, fileName: string) => {
    setDownloadDocumentState({ inProgress: true });
    try {
      await dispatch(actions.downloadIdentityDocument(documentLink, fileName));
      setDownloadDocumentState({ inProgress: false, success: true });
    } catch (e) {
      setDownloadDocumentState({ inProgress: false, success: false, error: e });
    }
  };

  return (
    <div>
      <List dense={true}>
        {(allowedIdentityDocuments || []).map((allowedIdentityDocument, index) => {
          const driverIdentityDocument = (identityDocuments ?? []).find((identityDocument) => {
            return identityDocument.documentName === allowedIdentityDocument.value;
          });
          return (
            <ListItem>
              <ListItemAvatar>
                {driverIdentityDocument ? (
                  <SuccessIcon className={styles.successIcon} />
                ) : (
                  <WarningIcon />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={t(`identityDocument.types.${allowedIdentityDocument.value}`)}
                secondary={''}
              />
              <ListItemSecondaryAction className={styles.itemActionContainer}>
                {!driverIdentityDocument || openUpload === allowedIdentityDocument.value ? (
                  <UploadFile
                    onUpload={(file: File, onUploadProgress: any) =>
                      uploadIdentityDocument(file, allowedIdentityDocument.value, onUploadProgress)
                    }
                  />
                ) : (
                  <DataColorActionIcon
                    onClick={() => toggleUpload(allowedIdentityDocument.value)}
                    tooltip={t('user:identityDocumentUpload.uploadIdentityDocument')}
                    loading={false}
                  >
                    <UploadIcon />
                  </DataColorActionIcon>
                )}
                {driverIdentityDocument && (
                  <DataColorActionIcon
                    onClick={() =>
                      downloadIdentityDocument(
                        driverIdentityDocument.downloadLink,
                        driverIdentityDocument.fileName,
                      )
                    }
                    tooltip={t('user:identityDocumentUpload.downloadIdentityDocument')}
                    loading={downloadDocumentState.inProgress}
                    key={'action'}
                  >
                    <DownloadIcon />
                  </DataColorActionIcon>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default UploadIdentityDocumentsSection;
